import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { Alert, AlertTitle, Button, Container, Link, Typography } from '@mui/material';

import useAuth from '../hooks/useAuth';
import { PATH_AFTER_LOGIN } from '../config';
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node
};
export default function RoleBasedGuard({ accessibleRoles, children }) {
  const { user } = useAuth();

  return (<div className='no-scroll'>
    {!accessibleRoles.includes(user.role) ?
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          <Typography variant='subtitle2'>
          You do not have permission to access this page. If you believe this is a mistake, please contact support.
          </Typography>
          <br />
          <Link component={RouterLink} to={PATH_DASHBOARD.home.root} sx={{color: 'error.lighter'}}>Go back to safety</Link>
        </Alert>
      </Container>
      :
      <>
        {React.Children.toArray(children)}
      </>
    }
  </div>);
}
