import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { Manager } from 'socket.io-client';
import { sentenceCase } from 'change-case';
// hooks
import useAuth from '../hooks/useAuth';
// utils
import { ServerUtils } from '../api_utils';

// ----------------------------------------------------------------------

const initialState = {
  socketManager: undefined,
  socket: undefined,
  serverPlatformVersion: ServerUtils.getVersion(),
  notification: undefined,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { socketManager, socket } = action.payload;
    return {
      ...state,
      socketManager,
      socket,
    };
  },
  FIRST_RESPONSE: (state, action) => {
    const { serverPlatformVersion, notification } = action.payload;
    return {
      ...state,
      serverPlatformVersion,
      notification,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const SocketContext = createContext({
  ...initialState,
});

// ----------------------------------------------------------------------

SocketProvider.propTypes = {
  children: PropTypes.node,
};

let initialized = false;
let loggedOut = true;

function SocketProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user, setUser } = useAuth();

  const initialize = async () => {
    try {
      const socketEndpoint = window.location.hostname.includes("localhost") ? "http://localhost:3003" : "https://socket.steincounseling.org"
      console.log(`[Socket] Initializing connection to '${socketEndpoint}'...`)
      const socketManager = new Manager(socketEndpoint)
      const socket = socketManager.socket("/");
      socket.on("disconnect", () => {
        console.log(`[Socket] Disconnected from '${socketEndpoint}'.`)
      });
      socket.on("connect", () => {
        console.log(`[Socket] Connected to '${socketEndpoint}'!`)
        socket.emit("connection_establish", {...user, current_page: window.location.pathname, client_version: ServerUtils.getVersion()})
        dispatch({
          type: 'INITIALIZE',
          payload: {
            socketManager,
            socket,
          },
        });
      });
      socket.on("reconnect", () => {
        console.log(`[Socket] Received Reconnect Request...`)
        initialize();
      });
      socket.on("connection_established", (data) => {
        console.log(`[Socket] Connection Established`, data)
        dispatch({
          type: 'FIRST_RESPONSE',
          payload: {
            serverPlatformVersion: data.platform_version,
            notification: data.notification,
          },
        });
        if (data.updatedUser) {
          loggedOut = false;
          if (data.updatedUser.role !== user.role){
            window.alert(`Your account role has been changed from ${sentenceCase(user.role)} to ${sentenceCase(data.updatedUser.role)}.\nIn order for these change to be applied, you will need to re-login.`)
          } else {
            setUser(data.updatedUser);
          }
        }
      })
      socket.on("refresh-page", () => {
        window.location.reload(true);
      });
    } catch (error) {
      console.log('[Socket] Error:', error)
    }
    initialized = true;
  };

  useEffect(() => {
    if (!initialized && user) {
      // user has loaded the site, but they are already logged in, initialize the socket.
      initialize()
    } else if (initialized && !user) {
      console.log('[Socket] Logged out, disconnecting...')
      state.socket?.disconnect();
      loggedOut = true;
    } else if (initialized && user && loggedOut) {
      console.log('[Socket] Logged in, connecting...')
      state.socket?.connect();
    }
  }, [user]);

  return (
    <SocketContext.Provider
      value={{
        ...state
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}

export { SocketContext, SocketProvider };
