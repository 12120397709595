import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Container, Link, Stack, Typography, useTheme } from '@mui/material';
import { alpha, styled,  } from '@mui/material/styles';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Page from '../../components/Page';
// utils
import cssStyles from '../../utils/cssStyles';
// sections
import { LoginForm } from '../../sections/auth/login';

// ----------------------------------------------------------------------
const homeImages = [
  `../../img/home_headers/1.webp`,
  `../../img/home_headers/2.webp`,
  `../../img/home_headers/3.webp`,
  `../../img/home_headers/4.webp`,
  `../../img/home_headers/5.webp`,
]

const getRandomHomeImage = () => {
  return homeImages[Math.floor(Math.random() * homeImages.length)];
}

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled('div')(({ theme }) => ({
  width: "90vw",
  background: `url(${getRandomHomeImage()})`,
  backgroundSize: 'cover',
  borderStyle: 'solid',
  borderWidth: 16,
  borderColor: theme.palette.background.default,
  borderRadius: 32,
  webkitBorderRadius: 64,
  overflow: 'hidden',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'start',
  padding: theme.spacing(6, 6),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();
  const theme = useTheme();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>

        <Container maxWidth="sm">
          <ContentStyle>
            <Logo />
            <Stack direction="row" alignItems="center" sx={{ mb: 5, mt: 8 }}>
              <Box>
                <Typography variant="h3" gutterBottom>
                  Sign in
                </Typography>
                <Typography variant="body2">
                  New user?&nbsp;
                  <Link variant="body2" component={RouterLink} to={PATH_AUTH.register}>
                    <strong>Create an account</strong>
                  </Link>
                </Typography>
              </Box>
            </Stack>

            <LoginForm />
          </ContentStyle>
        </Container>

        {mdUp && (
          <SectionStyle>
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" alignContent="center" sx={{height: '100%', width: '100%', ...cssStyles().bgBlur({blur: 4, color: alpha(theme.palette.background.paper, .5) }),}}>
              <Stack direction="row" justifyContent="center" alignItems="center" alignContent="center">
                <Logo width={200} height={200}/>
                <Stack>
                  <Typography variant='h3'>Stein Counseling & Consulting Services</Typography>
                  <Typography variant='h4'>Solutions for Individuals, Families & Communities.</Typography>
                </Stack>
              </Stack>
            </Box>
          </SectionStyle>
        )}
      </RootStyle>
    </Page>
  );
}
