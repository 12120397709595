/* eslint-disable */
import ServerUtils from "./helpers";

export default class BlogUtils {
  static async getAllPosts(){
    return ServerUtils.GET(`api/blog/`)
  }
  static async getPostById(id){
    return ServerUtils.GET(`api/blog/get/${id}`)
  }
  static async addPost(post){
    return ServerUtils.POST(`api/blog/add`, {post: post})
  }
  static async updatePost(post){
    return ServerUtils.POST(`api/blog/update`, {post: post})
  }
  static async deletePost(postId){
    return ServerUtils.GET(`api/blog/delete/${postId}`)
  }
  static async viewPost(post){
    return ServerUtils.POST(`api/blog/view`, {post})
  }
}