import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useResponsive from '../../hooks/useResponsive';
import useSettings from '../../hooks/useSettings';
import useSocket from '../../hooks/useSocket';
// config
import { HEADER, NAVBAR } from '../../config';
//
import Scrollbar from '../../components/Scrollbar';
import DashboardHeader from './header';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import NavbarVertical from './navbar/NavbarVertical';
import { NavSectionHorizontal } from '../../components/nav-section';
import navConfig from './navbar/NavConfig';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout({ settingsOpen, setSettingsOpen, onlineUsersOpen, setOnlineUsersOpen }) {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const { themeLayout } = useSettings();
  const isDesktop = useResponsive('up', 'lg');
  const [open, setOpen] = useState(false);
  const verticalLayout = themeLayout === 'vertical';
  const location = useLocation();
  const { socket } = useSocket();

  useEffect(() => {
    if (socket) {
      socket.emit('change_page', { path: location.pathname })
    }
  }, [location]);

  if (verticalLayout) {
    return (
      <Scrollbar sx={{ height: 1 }}>
        <DashboardHeader
          onOpenSidebar={() => setOpen(true)}
          verticalLayout={verticalLayout}
          settingsOpen={settingsOpen}
          setSettingsOpen={setSettingsOpen}
          onlineUsersOpen={onlineUsersOpen}
          setOnlineUsersOpen={setOnlineUsersOpen}
          headerContent={isDesktop ? <NavSectionHorizontal navConfig={navConfig()} /> : <></>} />

        {!isDesktop && (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 8}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: 1,
            },
          }}
        >
          <Outlet />
        </Box>
      </Scrollbar>
    );
  }

  return (
    <Scrollbar sx={{ height: 1 }}>
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        <DashboardHeader
          isCollapse={isCollapse}
          onOpenSidebar={() => setOpen(true)}
          settingsOpen={settingsOpen}
          setSettingsOpen={setSettingsOpen}
          onlineUsersOpen={onlineUsersOpen}
          setOnlineUsersOpen={setOnlineUsersOpen} />

        <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

        <MainStyle collapseClick={collapseClick}>
          <Outlet />
        </MainStyle>
      </Box>
    </Scrollbar>
  );
}
