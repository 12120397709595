import PropTypes from 'prop-types';
import { createContext, useMemo } from 'react';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';
// utils
import getColorPresets, { colorPresets, defaultPreset } from '../utils/getColorPresets';
// config
import { defaultSettings } from '../config';

// ----------------------------------------------------------------------

const initialState = {
  ...defaultSettings,
  onChangeMode: () => {},
  onToggleMode: () => {},
  onChangeDirection: () => {},
  onChangeColor: () => {},
  onToggleStretch: () => {},
  onChangeLayout: () => {},
  onToggleLiveData: () => {},
  onResetSetting: () => {},
  setColor: defaultPreset,
  colorOption: [],
};

const SettingsContext = createContext(initialState);

// ----------------------------------------------------------------------

SettingsProvider.propTypes = {
  children: PropTypes.node,
};

function SettingsProvider({ children }) {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: initialState.themeMode,
    themeDirection: initialState.themeDirection,
    themeColorPresets: initialState.themeColorPresets,
    themeColorCustom: initialState.themeColorCustom,
    themeStretch: initialState.themeStretch,
    themeLayout: initialState.themeLayout,
    liveData: initialState.liveData,
  });

  const onChangeMode = (event) => {
    setSettings({
      ...settings,
      themeMode: event.target.value,
    });
  };

  const onToggleMode = () => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
    });
  };

  const onChangeDirection = (event) => {
    setSettings({
      ...settings,
      themeDirection: event.target.value,
    });
  };

  const onChangeColor = (event, customColor = undefined) => {
    const newSettings = {
      ...settings,
    }
    if (customColor){
      newSettings.themeColorCustom = customColor;
      newSettings.themeColorPresets = "custom"
    } else {
      newSettings.themeColorPresets = event.target.value
    }

    setSettings(newSettings);
  };

  const onChangeLayout = (event) => {
    setSettings({
      ...settings,
      themeLayout: event.target.value,
    });
  };

  const onToggleStretch = () => {
    setSettings({
      ...settings,
      themeStretch: !settings.themeStretch,
    });
  };

  const onToggleLiveData = () => {
    setSettings({
      ...settings,
      liveData: !settings.liveData,
    });
  };

  const onResetSetting = () => {
    setSettings({
      themeMode: initialState.themeMode,
      themeLayout: initialState.themeLayout,
      themeStretch: initialState.themeStretch,
      themeDirection: initialState.themeDirection,
      themeColorPresets: initialState.themeColorPresets,
      themeColorCustom: initialState.themeColorCustom,
      liveData: initialState.liveData
    });
  };
  
  const getCustomColorPresets = useMemo(() => {
    const filtered = colorPresets.filter(x => x.name !== "custom");
    filtered.push(settings.themeColorCustom)
    const result = filtered.map((color) => ({
      name: color ? color.name : 'custom',
      value: color ? color.main : '#fff',
    }))
    return result
  }, [settings]) 

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        // Mode
        onChangeMode,
        onToggleMode,
        // Direction
        onChangeDirection,
        // Color
        onChangeColor,
        setColor: settings.themeColorPresets === "custom" ? settings.themeColorCustom : getColorPresets(settings.themeColorPresets),
        colorOption: getCustomColorPresets,
        // Stretch
        onToggleStretch,
        // Navbar Horizontal
        onChangeLayout,
        // Live Data,
        onToggleLiveData,
        // Reset Setting
        onResetSetting,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsContext, SettingsProvider };
