// ----------------------------------------------------------------------

import { alpha } from "@mui/material";
import cssStyles from "../../utils/cssStyles";

export default function Popover(theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.dropdown,
          borderRadius: Number(theme.shape.borderRadius) * 1.5,
          ...cssStyles().bgBlur({blur: 16, color: alpha(theme.palette.background.paper, .5) })
        },
      },
    },
  };
}
