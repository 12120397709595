export default function hasRole (user, role, exactRole = false) {
  if (!role) return true;
  if (!user.role) return false;
  if (user.role === "developer") return true;
  if (exactRole) return role === user.role;
  if (role === "admin"){
    return user.role === "admin"
  }
  if (role === "manager"){
    return user.role === "manager" || user.role === "admin"
  }
  if (role === "referral"){
    return user.role === "referral" || user.role === "employee" || user.role === "manager" || user.role === "admin" 
  }
  if (role === "employee"){
    return user.role === "employee" || user.role === "manager" || user.role === "admin"
  }
  return user.role === role
}