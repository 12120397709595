// hooks
import { useEffect, useMemo, useState } from 'react';
import ServerUtils from '../api_utils/helpers';
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  const imageUrl = useMemo(() => {
    if (user) {
      return user.imageUrl.length > 32 ? user.imageUrl : ServerUtils.getUserImageUrl(user)
    }
    return undefined;
  }, [user]);

  return (
    <Avatar
      src={imageUrl}
      alt={user?.username}
      color={createAvatar(user?.name).color}
      {...other}
    >
      {createAvatar(user?.name).name}
    </Avatar>
  );
}
