
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import en from 'date-fns/locale/en-US';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Card, Container, Dialog, DialogActions, DialogTitle, Divider, Link, Stack, Typography, alpha, styled, useTheme } from '@mui/material';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Logo from './components/Logo';
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ChartStyle } from './components/chart';
import Settings from './components/settings';
import useLocalStorage from './hooks/useLocalStorage';
import { PATH_AUTH } from './routes/paths';
import OnlineUsersPopover from './components/online-users';
import Page from './components/Page';
import Scrollbar from './components/Scrollbar';
import Spinner from './components/Spinner';


// ----------------------------------------------------------------------
const SeverErrorIllustration = lazy(() => import('./assets/illustration_500'));
const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined,
      errorInfo: undefined,
      errorOpen: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.log(error);
    console.log(errorInfo);
    this.setState(prev => ({ ...prev, error: `${error}`, errorInfo }))
  }


  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Page title="Oops" sx={{ height: 1 }}>
          <RootStyle>
            <Container>
              <Card sx={{ maxWidth: 480, margin: 'auto', p: 3 }}>
                <Typography variant="h3" paragraph textAlign="center">
                  Oops!
                </Typography>
                <Typography sx={{ color: 'text.secondary' }} textAlign="center">It seems like the website ran into an error!</Typography>

                {this.state.errorOpen ?
                  <Stack sx={{ my: { xs: 2, sm: 4 } }} spacing={1}>
                    <Typography variant='caption' sx={{ color: 'indianred' }}>
                      <strong>{this.state.error}</strong>
                    </Typography>
                    <Card variant='outlined' sx={{ borderRadius: 1, boxShadow: 'none', }}>
                      <Typography variant='subtitle2' sx={{py: .5}} textAlign="center">
                        Stack Trace
                      </Typography>
                      <Divider />
                      <Scrollbar sx={{ height: 150, px: 1 }} >
                        <Typography variant='caption'>
                          {this.state.errorInfo.componentStack}
                        </Typography>
                      </Scrollbar>
                    </Card>
                  </Stack>
                  :
                  <Suspense fallback={<Spinner />}>
                    <SeverErrorIllustration sx={{ height: 200, my: { xs: 5, sm: 7 } }} />
                  </Suspense>
                }

                <Stack direction="column" justifyContent="center" alignContent="center" alignItems="center" spacing={1}>
                  <Button size="large" variant="contained" onClick={() => window.location.reload()}>
                    Reload Page
                  </Button>
                  <Button size="small" onClick={() => {
                    this.setState(prev => ({ ...prev, errorOpen: !prev.errorOpen }))
                  }}>
                    View Details
                  </Button>
                </Stack>
              </Card>
            </Container>
          </RootStyle>
        </Page>
      )
    }

    return this.props.children;
  }
}
export default function App() {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [onlineUsersOpen, setOnlineUsersOpen] = useState(false);
  // const [enteredPassword, setEnteredPassword] = useLocalStorage('access_granted', false);
  const [previouslyVisited, setPreviouslyVisited] = useLocalStorage('previously_visited', false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (!previouslyVisited) {
      setDialogOpen(true);
    }
  }, [previouslyVisited])

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={en}>
                <ErrorBoundary>
                <MotionLazyContainer>
                  <ProgressBarStyle />
                  <ChartStyle />
                  <Settings open={settingsOpen} setOpen={setSettingsOpen} />
                  <OnlineUsersPopover open={onlineUsersOpen} setOpen={setOnlineUsersOpen}/>
                  <ScrollToTop />

                  <Router
                    settingsOpen={settingsOpen}
                    setSettingsOpen={setSettingsOpen}
                    onlineUsersOpen={onlineUsersOpen}
                    setOnlineUsersOpen={setOnlineUsersOpen} />
                  {/* {enteredPassword ?
                    <WebsiteLocked setEnteredPassword={setEnteredPassword}/>
                      :
                  } */}
                  {!previouslyVisited &&
                    <Dialog
                      open={dialogOpen}
                      onClose={() => setDialogOpen(false)}
                      fullWidth
                      maxWidth="md"
                      PaperProps={{
                        sx: {
                          background: alpha(theme.palette.grey[800], .5),
                          backdropFilter: 'blur(256px)',
                          filter: 'brightness(1.2)'
                        }
                      }}
                    >
                      <DialogTitle sx={{ p: 2 }}>
                        <Stack justifyContent="space-between" display="flex" flexDirection="row" alignContent="center" alignItems="center">
                          <Typography variant='h3'>Welcome to our new website!</Typography>
                          <Logo />
                        </Stack>
                      </DialogTitle>
                      <Stack spacing={1} sx={{ p: 2 }}>
                        <Typography variant='subtitle1'>
                          Our website has been upgraded! If you had an account on our old website, all of your data has been migrated, but you will need to <Link component={RouterLink} to={PATH_AUTH.forgotPassword} onClick={() => setPreviouslyVisited(true)}><strong>Reset Your Password</strong></Link> in order to re-gain access.
                          If you are a returning visitor, you will still be able to find all of our resources as you did on the old website.
                          <br />
                          <br />
                          We hope you enjoy!
                          <br />
                          - Stein Counseling Team
                        </Typography>

                      </Stack>
                      <DialogActions sx={{ p: '16px !important' }}>
                        <Button onClick={() => {
                          setDialogOpen(false)
                          setPreviouslyVisited(true)
                        }}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  }
                </MotionLazyContainer>
                </ErrorBoundary>
              </LocalizationProvider>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
