/* eslint-disable */
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, color = "primary", sx, width = 48, height = 48 }) {
  const theme = useTheme();

  const logo = (
    <Box sx={{ width: width, height: height, ...sx }}>
      <svg width={`${width}pt`} height={`${height}pt`} viewBox="0 0 1000 1000"
        preserveAspectRatio="xMidYMid meet">
        <defs>
          <linearGradient id="Gradient" x1="0" x2="0.5" y1="1" y2="0">
            <stop offset="0%" stopColor={theme.palette[color].light} />
            <stop offset="70%" stopColor={theme.palette[color].main} />
            <stop offset="100%" stopColor={theme.palette[color].dark} />
          </linearGradient>
        </defs>

        <g transform="translate(-175,925) scale(0.110,-0.110)"
          fill="url(#Gradient)" stroke="none">
          <path
            d="M4470 8054 c-159 -19 -328 -94 -429 -190 -135 -127 -221 -344 -221
              -559 0 -121 52 -376 95 -472 8 -17 15 -34 15 -38 0 -11 106 -219 144 -279 20
              -33 36 -65 36 -70 0 -6 -39 -23 -88 -38 -112 -34 -496 -170 -556 -196 -69 -31
              -112 -68 -175 -152 -236 -310 -379 -662 -437 -1070 -22 -159 -25 -444 -5 -515
              42 -148 123 -305 235 -454 39 -52 77 -102 85 -113 20 -26 31 -22 31 12 0 52
              25 33 59 -45 41 -93 40 -110 -22 -345 -57 -218 -71 -300 -64 -370 4 -30 11
              -136 16 -235 11 -194 14 -225 37 -355 18 -101 42 -156 88 -205 28 -29 40 -35
              58 -30 53 16 68 49 53 120 -6 25 -2 35 24 58 36 31 35 28 18 87 -14 47 -11
              129 14 300 6 47 12 102 13 123 2 30 9 44 35 64 17 14 35 23 38 19 3 -3 0 -65
              -7 -138 -7 -73 -16 -167 -20 -208 -5 -54 -3 -82 6 -101 10 -20 11 -43 4 -90
              -5 -35 -21 -149 -35 -254 -14 -104 -28 -202 -31 -217 -5 -24 -1 -30 23 -39 54
              -21 244 -49 261 -39 15 8 14 9 -5 10 -28 0 -189 37 -227 52 -25 10 -28 15 -22
              47 3 20 20 133 36 251 40 280 34 260 71 260 l31 0 -4 -63 c-3 -34 -18 -151
              -33 -260 -15 -108 -25 -203 -22 -210 6 -15 190 -77 229 -77 39 0 115 36 131
              62 8 13 18 48 21 78 7 56 30 105 45 96 5 -3 14 -43 20 -88 7 -46 14 -98 17
              -115 6 -39 34 -56 119 -72 75 -14 224 -14 278 0 54 13 118 57 135 91 10 22 9
              54 -8 185 -11 87 -25 196 -31 243 -15 121 -18 130 -34 130 -23 0 -18 36 7 52
              21 14 21 18 10 69 -17 78 -33 242 -24 251 5 5 23 -5 40 -20 l32 -29 -5 -194
              c-8 -254 -4 -283 46 -331 23 -22 53 -40 75 -44 84 -16 169 43 192 131 16 65 7
              163 -19 191 -36 39 -24 124 72 529 32 131 38 174 30 187 -16 26 -14 28 18 28
              38 0 42 -8 30 -51 -5 -19 -24 -97 -43 -174 l-34 -140 31 -3 30 -3 -7 -87 c-4
              -48 -13 -145 -20 -217 -13 -127 -12 -130 7 -148 20 -17 20 -22 8 -75 -52 -240
              -67 -443 -35 -503 29 -56 136 -82 407 -100 198 -13 585 -6 670 12 48 10 58 16
              65 38 13 47 5 342 -12 445 -10 61 -13 103 -7 109 19 19 44 2 55 -38 8 -27 20
              -44 38 -53 35 -16 134 -18 153 -2 8 6 15 33 17 58 2 33 8 47 18 49 8 1 62 7
              120 13 194 22 475 61 507 71 17 6 43 19 57 30 23 18 26 27 26 88 0 155 -53
              429 -161 832 -21 81 -39 150 -39 153 0 3 8 6 18 6 45 1 46 15 5 91 -60 110
              -87 196 -132 414 -30 149 -42 167 -77 123 -11 -13 -75 -70 -144 -127 -192
              -160 -218 -187 -226 -237 -3 -24 -1 -90 6 -149 13 -115 9 -145 -15 -125 -9 7
              -15 30 -15 54 0 22 -7 70 -16 106 -14 60 -14 70 2 120 9 30 28 68 43 84 37 40
              250 218 313 262 29 19 60 43 69 52 9 10 59 55 113 101 53 47 121 115 152 152
              116 141 195 307 164 344 -12 15 -6 35 11 35 21 0 39 30 39 64 0 20 -4 36 -9
              36 -4 0 -11 28 -15 63 -12 107 -93 567 -112 629 -3 9 -49 42 -102 74 -53 31
              -125 74 -160 95 -35 22 -65 39 -67 39 -12 0 -205 123 -205 130 0 5 10 25 21
              45 l21 36 32 -26 c56 -47 114 -68 167 -62 111 13 236 105 287 209 l30 62 -38
              38 c-43 44 -47 45 -92 19 -31 -18 -56 -25 -86 -22 -7 0 -19 16 -26 34 -15 35
              -8 116 34 392 43 285 37 430 -24 562 -46 97 -173 171 -313 181 -89 6 -215 -12
              -312 -44 l-74 -24 -71 25 c-146 51 -346 57 -446 14 -88 -38 -152 -127 -186
              -259 -13 -53 -15 -97 -10 -260 3 -107 10 -224 15 -260 5 -36 13 -121 17 -190
              l6 -125 -33 -24 -33 -25 -61 23 c-89 33 -98 32 -109 -10 -6 -27 -5 -49 8 -84
              9 -27 14 -51 11 -53 -6 -6 -237 58 -249 69 -6 5 -2 18 11 31 48 54 174 294
              221 423 16 44 43 130 58 190 25 96 29 129 29 255 0 167 -12 223 -78 359 -50
              104 -160 220 -255 270 -127 66 -194 83 -342 86 -74 2 -151 1 -170 -1z m-225
              -106 c-70 -46 -167 -147 -203 -210 -158 -280 -129 -640 83 -1053 108 -210 135
              -242 262 -319 192 -115 294 -114 486 3 45 28 88 51 96 51 50 0 -83 -97 -194
              -141 -153 -61 -260 -40 -452 88 -67 44 -104 63 -122 61 -14 -1 -31 1 -37 5
              -20 12 -142 230 -186 329 -94 216 -123 346 -122 548 0 139 3 164 27 239 53
              167 174 316 318 391 91 48 109 51 44 8z m1535 -302 c0 -3 -27 -17 -60 -32 -99
              -44 -155 -126 -184 -268 -22 -107 -20 -205 9 -451 32 -273 33 -345 3 -380 -22
              -25 -92 -50 -110 -39 -4 3 4 16 20 29 27 23 27 24 26 142 -1 65 -9 170 -18
              233 -55 384 -16 607 124 702 57 39 190 83 190 64z m608 -25 c-32 -10 -72 -22
              -90 -25 -56 -11 -61 -18 -43 -59 19 -46 19 -57 0 -57 -9 0 -20 12 -26 28 -6
              15 -19 37 -31 49 -19 20 -19 22 -1 30 42 21 166 51 213 51 31 1 29 -1 -22 -17z
              m52 -151 c185 -42 296 -152 331 -328 32 -167 -14 -482 -109 -741 -11 -29 -17
              -62 -15 -72 3 -10 25 -37 49 -59 24 -22 44 -45 44 -50 0 -26 -74 6 -127 53
              -30 27 -29 39 12 145 75 194 110 346 120 523 16 266 -67 411 -282 495 -91 35
              -145 63 -98 51 11 -3 45 -11 75 -17z m-445 -20 c-3 -5 -16 -10 -28 -10 -37 0
              -122 -45 -161 -86 -139 -143 -163 -407 -72 -810 26 -111 61 -202 103 -264 39
              -59 26 -107 -21 -76 -31 20 -79 105 -72 129 2 10 -8 69 -24 130 -55 215 -73
              333 -73 477 0 74 5 162 12 195 35 163 123 282 232 314 46 13 112 14 104 1z
              m-695 -955 c17 -9 31 -26 35 -43 3 -16 21 -52 40 -80 19 -29 28 -52 22 -52 -7
              0 -35 11 -63 24 -45 21 -52 28 -67 76 -29 89 -23 104 33 75z m1685 -4 c7 -13
              -33 -34 -51 -27 -19 7 -18 24 4 29 29 8 41 7 47 -2z m-1251 -233 c21 -42 21
              -44 -15 -57 -31 -11 -142 -4 -135 8 3 3 22 15 43 26 21 10 49 27 62 37 13 10
              25 18 27 18 1 0 10 -14 18 -32z m-234 -12 c25 -15 30 -22 18 -24 -21 -4 -90
              29 -96 47 -6 17 29 7 78 -23z m-2010 -68 c0 -7 -14 -26 -31 -43 -83 -81 -190
              -243 -271 -412 -155 -322 -231 -647 -232 -988 -1 -161 1 -187 22 -249 31 -95
              101 -241 151 -314 38 -57 41 -65 41 -131 0 -39 -4 -71 -8 -71 -13 0 -142 193
              -194 290 -79 147 -100 224 -106 382 -5 158 16 368 54 543 72 328 248 686 446
              909 66 75 128 115 128 84z m2300 -3 c-1 -6 -92 -56 -203 -113 -111 -57 -212
              -109 -223 -117 -14 -9 -42 -66 -79 -159 -51 -130 -75 -170 -89 -148 -4 7 7 37
              90 243 l35 85 226 112 c125 62 231 111 236 109 4 -1 8 -7 7 -12z m-205 -604
              c-7 -23 -25 -29 -25 -8 0 14 21 39 28 33 2 -3 1 -14 -3 -25z m331 10 c39 -18
              66 -41 47 -41 -4 0 -32 7 -61 14 -70 19 -127 5 -211 -52 -65 -45 -91 -51 -111
              -27 -10 12 -10 21 -1 41 11 26 31 25 31 -2 0 -20 3 -19 70 27 92 63 160 74
              236 40z m-559 -13 c62 -18 48 -30 -26 -22 -83 8 -121 -5 -209 -69 -184 -134
              -368 -517 -389 -813 -4 -63 -2 -88 10 -113 14 -28 14 -33 1 -38 -31 -12 -39
              15 -39 130 0 82 7 139 24 212 60 258 216 533 361 640 27 20 57 50 67 65 17 29
              17 29 92 24 42 -3 91 -10 108 -16z m1370 -127 c29 -93 53 -225 56 -315 l2 -69
              -32 -13 c-31 -13 -33 -13 -37 9 -10 49 -40 317 -44 390 -4 72 -3 79 14 75 13
              -2 25 -25 41 -77z m-507 -97 c107 -154 188 -362 217 -553 14 -90 14 -117 4
              -151 -15 -49 -31 -44 -24 8 6 46 -18 188 -52 307 -27 92 -138 336 -179 390
              -12 16 -40 57 -60 90 l-38 60 41 -40 c23 -22 64 -72 91 -111z m-2526 127 c3
              -4 15 -75 27 -156 l22 -148 -39 -32 c-22 -18 -41 -31 -43 -29 -6 6 -20 94 -27
              175 -6 63 -1 96 28 172 11 27 22 34 32 18z m1943 -45 c97 -29 145 -51 168 -77
              15 -16 4 -14 -66 13 -74 27 -96 31 -184 31 -79 0 -112 -5 -160 -23 -217 -83
              -297 -237 -281 -541 4 -66 17 -157 30 -215 24 -96 71 -239 86 -259 12 -17 29
              -75 22 -75 -15 0 -70 98 -96 170 -51 140 -78 291 -79 440 -2 203 28 307 116
              398 55 57 107 87 203 116 75 24 143 43 154 45 3 0 42 -10 87 -23z m289 -138
              c75 -82 104 -170 112 -339 7 -184 -20 -381 -50 -351 -6 6 -4 47 6 114 20 131
              20 244 2 341 -15 76 -72 215 -103 249 -9 10 -12 18 -7 18 6 0 24 -15 40 -32z
              m-2316 -66 c0 -10 7 -43 15 -75 15 -57 15 -57 -15 -115 -48 -95 -64 -179 -63
              -337 0 -107 5 -164 23 -240 12 -55 24 -113 27 -128 l6 -29 -52 27 c-52 26 -63
              51 -22 49 11 0 21 4 21 9 0 5 -36 63 -80 130 -52 77 -78 127 -74 137 6 15 40
              116 141 427 49 153 73 199 73 145z m618 2 c139 -31 241 -103 292 -207 44 -90
              53 -157 47 -357 -4 -149 -8 -190 -32 -280 -35 -136 -79 -249 -119 -308 l-31
              -47 156 -51 c171 -57 208 -73 239 -106 12 -13 23 -18 28 -12 5 5 18 50 31 99
              13 50 27 95 32 102 6 6 45 25 87 41 42 17 129 51 193 76 l116 46 112 -51 c61
              -28 129 -53 150 -56 50 -7 116 15 214 71 75 43 94 45 83 8 -9 -28 -211 -122
              -262 -122 -41 0 -174 57 -232 100 l-28 21 -65 -24 c-216 -80 -316 -122 -332
              -137 -10 -9 -28 -57 -41 -106 -84 -326 -103 -384 -123 -384 -17 0 -17 0 16
              120 23 84 25 119 11 171 l-10 37 -215 72 c-118 40 -215 74 -215 77 0 3 14 21
              30 40 99 112 180 406 180 649 0 199 -30 296 -116 378 -58 56 -148 101 -254
              127 -47 12 -89 25 -93 29 -11 10 72 2 151 -16z m-346 -59 c-132 -61 -181 -119
              -214 -254 -65 -259 37 -741 182 -864 21 -17 75 -52 121 -77 45 -25 80 -49 78
              -55 -5 -15 -25 -9 -124 40 -94 45 -124 73 -177 165 -14 25 -40 63 -58 85 -44
              55 -70 130 -101 290 -18 93 -18 324 0 395 39 153 119 239 266 286 67 21 83 15
              27 -11z m2828 -174 c0 -23 -16 -36 -25 -21 -9 14 1 40 15 40 5 0 10 -9 10 -19z
              m54 -98 c-186 -253 -382 -487 -466 -555 -59 -48 -94 -65 -196 -92 -48 -14
              -118 -36 -156 -51 -44 -16 -73 -22 -79 -16 -14 14 61 47 167 72 50 12 112 32
              137 45 51 25 159 110 159 125 0 5 18 32 40 60 22 28 40 55 40 60 0 5 8 9 18 9
              38 0 209 216 274 345 51 104 62 115 110 115 l38 0 -86 -117z m-652 -358 c161
              -50 200 -65 195 -79 -5 -14 -20 -12 -152 29 -178 55 -159 58 -184 -25 -37
              -121 -87 -220 -113 -220 -15 0 -8 27 31 111 21 46 46 114 56 152 14 51 22 67
              36 67 9 0 68 -16 131 -35z m-1014 -17 c-3 -7 -63 -32 -134 -55 -181 -60 -179
              -59 -200 -28 -20 30 -14 41 14 26 15 -8 46 -1 138 28 124 41 189 51 182 29z
              m-1633 -167 c3 -5 1 -12 -5 -16 -13 -8 -50 3 -50 16 0 12 47 12 55 0z m-99
              -73 c-29 -40 -56 -105 -62 -151 -6 -39 -1 -56 45 -151 38 -80 54 -125 61 -178
              13 -103 53 -141 168 -154 72 -8 80 -19 20 -27 -67 -9 -135 10 -179 50 l-39 35
              0 -26 c0 -31 -11 -33 -35 -6 -11 12 -20 45 -25 88 -5 47 -20 94 -48 155 -32
              68 -38 88 -27 97 7 5 15 39 19 74 9 90 32 159 62 190 35 34 64 37 40 4z m2705
              -109 c18 -45 -4 -106 -51 -137 -37 -25 -48 -40 -34 -51 23 -16 74 -83 74 -97
              0 -24 -52 8 -89 55 -46 58 -42 81 21 120 32 19 36 26 28 41 -16 30 -12 78 8
              89 25 15 28 14 43 -20z m-1769 -194 c1 -5 -41 -31 -92 -57 -144 -73 -150 -60
              -12 24 75 45 102 54 104 33z m-549 -72 c8 -27 24 -74 36 -105 29 -80 27 -111
              -9 -141 -33 -28 -68 -42 -78 -32 -3 3 13 23 37 43 l43 37 -36 107 c-40 118
              -43 138 -22 138 9 0 21 -20 29 -47z m2477 18 c0 -23 -26 -46 -73 -66 -70 -29
              -79 -12 -14 30 23 16 48 37 55 47 15 21 32 15 32 -11z m124 -286 c9 -49 16
              -107 16 -128 0 -34 -3 -38 -30 -43 -16 -4 -30 -8 -30 -10 0 -1 9 -63 19 -136
              45 -310 76 -688 57 -700 -22 -15 -26 0 -31 117 -9 213 -65 687 -85 725 -20 38
              -13 48 31 43 l42 -6 -6 34 c-19 104 -28 182 -23 196 12 29 24 0 40 -92z m-350
              38 c10 -62 7 -171 -5 -179 -21 -12 -27 12 -31 114 -3 89 -1 102 13 102 11 0
              18 -12 23 -37z m-887 -210 c-7 -259 -43 -761 -57 -798 -8 -20 -13 -23 -32 -16
              -31 11 -33 53 -14 242 9 82 16 156 16 163 0 8 7 21 15 29 9 10 18 54 25 128 5
              63 13 137 16 165 5 46 3 52 -15 57 -19 5 -20 7 -7 32 8 15 17 62 21 104 8 98
              13 121 27 121 8 0 9 -61 5 -227z m-1791 37 c-37 -150 -56 -257 -57 -318 -2
              -60 15 -86 71 -110 21 -9 30 -17 21 -20 -19 -6 -112 16 -134 33 -51 38 -38
              181 45 482 l30 112 25 -37 25 -37 -26 -105z m329 -40 c16 -69 16 -333 0 -510
              -25 -257 -25 -252 6 -267 14 -7 88 -16 165 -19 76 -4 141 -8 143 -10 2 -2 -61
              -4 -141 -4 -152 0 -253 14 -262 36 -3 7 2 73 10 146 8 73 17 255 20 403 3 149
              8 277 10 285 4 12 9 9 24 -10 10 -13 21 -36 25 -50z m895 46 c14 -38 30 -206
              30 -323 1 -92 4 -112 20 -131 18 -22 90 -43 90 -26 0 4 21 100 46 213 36 159
              44 209 35 223 -26 41 -15 45 69 24 88 -21 103 -43 27 -38 -41 3 -47 0 -52 -20
              -44 -197 -58 -261 -79 -363 -36 -174 -66 -395 -68 -508 -1 -54 -6 -101 -11
              -104 -5 -3 -19 4 -31 16 -20 21 -21 31 -19 169 1 126 5 159 27 231 14 47 32
              89 39 93 19 13 -34 54 -79 62 -22 3 -50 15 -64 25 -22 17 -23 22 -11 44 9 17
              11 49 7 108 -18 280 -19 329 -2 329 9 0 21 -11 26 -24z m1670 -26 c0 -5 -16
              -10 -35 -10 -39 0 -55 -23 -49 -70 1 -14 7 -61 13 -105 6 -44 18 -192 27 -328
              l16 -248 -37 -35 c-37 -34 -65 -44 -65 -24 0 6 14 19 31 28 l31 17 -6 160 c-8
              183 -33 484 -42 511 -4 11 -14 27 -22 37 -14 16 -14 19 1 34 31 32 137 57 137
              33z m-204 -21 c3 -6 8 -48 10 -93 1 -44 12 -151 24 -236 36 -261 48 -365 44
              -369 -12 -13 -25 26 -44 137 -39 229 -80 558 -71 567 8 9 30 5 37 -6z m-2734
              -433 c4 -6 8 -56 8 -111 1 -95 3 -125 30 -310 13 -88 37 -185 51 -199 14 -15
              11 -36 -5 -36 -41 0 -98 118 -122 255 -19 105 -38 367 -30 399 6 23 10 25 34
              20 15 -4 30 -12 34 -18z m2794 -420 c5 -22 4 -28 -7 -24 -14 5 -31 53 -21 63
              9 8 20 -7 28 -39z m168 -4 c-14 -87 -24 -112 -44 -112 -21 0 -40 27 -40 58 0
              23 68 104 81 96 5 -3 7 -22 3 -42z m-1364 -32 c0 -48 -14 -67 -26 -35 -8 20 5
              85 16 85 6 0 10 -22 10 -50z m294 25 c267 -38 537 -36 741 5 85 17 115 15 101
              -8 -8 -14 -126 -39 -246 -52 -130 -15 -359 -13 -515 5 -71 8 -137 15 -146 15
              -12 0 -20 -20 -33 -77 -24 -113 -46 -287 -46 -370 0 -58 -3 -73 -15 -73 -63 0
              -63 202 1 494 18 83 21 89 42 81 8 -3 60 -12 116 -20z m-1764 -55 c0 -11 -4
              -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m1408 -27
              c2 -33 -1 -44 -16 -52 -22 -12 -32 2 -32 44 0 68 43 75 48 8z m-735 -202 c35
              -13 57 -70 61 -155 3 -41 7 -80 11 -87 10 -16 85 -49 113 -49 12 0 22 -5 22
              -11 0 -23 -180 17 -196 43 -4 5 -14 57 -24 114 -10 58 -23 111 -30 119 -12 14
              -6 35 10 35 5 0 20 -4 33 -9z" 
          />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/" aria-label="Homepage">{logo}</RouterLink>;
}
