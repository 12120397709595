import PropTypes from 'prop-types';
// @mui
import { Avatar, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import cssStyles from '../../../../utils/cssStyles';
// hooks
// components
import { ServerUtils } from '../../../../api_utils';
import Image from '../../../../components/Image';
import createAvatar from '../../../../utils/createAvatar';
import Label from '../../../../components/Label';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  '&:before': {
    ...cssStyles().bgBlur({ blur: 1, color: theme.palette.primary.darker , opacity: .6}),
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderWidth: 1,
    borderColor: theme.palette.primary.darker,
    borderStyle: "solid",
  },
}));

const InfoStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: 'absolute',
  marginTop: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

ProfileCover.propTypes = {
  user: PropTypes.object,
};

export default function ProfileCover({ user }) {
  return (
    <RootStyle>
      <InfoStyle>
        
        <Avatar
          src={ServerUtils.getUserImageUrl(user)}
          alt={user?.username}
          color={ServerUtils.getUserImageUrl(user) ? 'default' : createAvatar(user?.name).color}
          sx={{
            mx: 'auto',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'common.white',
            width: { xs: 80, md: 128 },
            height: { xs: 80, md: 128 },
          }}
        >
          {createAvatar(user?.name).name}
        </Avatar>
        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: 'common.white',
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Typography variant="h4">{user?.name}</Typography>
          <Typography sx={{ opacity: 0.72 }}>{user?.username} {user.role === "developer" && <Label color='primary'>Developer</Label>}</Typography>
        </Box>
      </InfoStyle>
      {/* <Image alt="profile cover" src={cover} sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} /> */}
      <Image alt="profile cover" src={ServerUtils.getUserBannerUrl(user)} sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />
      
    </RootStyle>
  );
}
