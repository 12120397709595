/* eslint-disable */
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Button, Checkbox, Grid, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { sentenceCase } from 'change-case';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import Label from '../Label';
import { GenerateSignature } from '../Signature';
import { UploadMultiFile, UploadSingleFile } from '../upload';

export default function DynamicFormBuilder({ title, data, setData, config, onSubmit, successCallback }) {
    const [errorFields, setErrorFields] = useState([]);

    const { enqueueSnackbar } = useSnackbar()

    const handleSubmit = async () => {
        let foundError = false
        const foundErrorFields = []
        config.fields.forEach(field => {
            if (field.required) {
                if (data[field.name] === undefined || data[field.name] === "") {
                    foundError = true;
                    foundErrorFields.push(field.name)
                }
            }
        })
        if (!foundError) {
            onSubmit();
        } else {
            enqueueSnackbar(`You have ${foundErrorFields.length} incomplete fields!`, { variant: 'warning' })
            setErrorFields(foundErrorFields)
        }
    }

    return (
        <Stack spacing={3}>

            <Grid container>
                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                    <Typography align='center' variant='h3'>{title}</Typography>
                </Grid>
                {config.fields.map((field, index) => {
                    const columns = config.config.columns;
                    let gridMd = columns === 1 ? 12 : columns === 2 ? 6 : columns === 3 ? 4 : 3
                    if (index === config.fields.length - 1 && columns === 2 && ((index) % 2 == 0)) {
                        gridMd = 12;
                    }
                    if (field.type === "content") {
                        gridMd = 12;
                    }
                    return (
                        <Grid item xs={12} md={gridMd} sx={{ p: 1 }} key={`field-${index}`} >
                            {field.type === "content" &&
                                <div
                                    style={{ padding: "8px" }}
                                    dangerouslySetInnerHTML={{ __html: field.content }}
                                />
                            }
                            {(field.type === "text" || field.type === "password" || field.type === "tel") &&
                                <>
                                    <h4>{field.label} {field.required && <span style={{ color: 'indianred' }}>*</span>}</h4>
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label={undefined}
                                        value={field.type === "tel" ? formatPhoneNumber(data[field.name]) : data[field.name]}
                                        error={errorFields.includes(field.name)}
                                        onChange={(e) => {
                                            const update = { ...data };
                                            update[field.name] = e.target.value;
                                            setData(update)
                                        }} />
                                </>
                            }
                            {field.type === "signature" &&
                                <Stack>
                                    <h4>{field.label} {field.required && <span style={{ color: 'indianred' }}>*</span>}</h4>
                                    <GenerateSignature name={data[field.signatureInputFieldKey]} setValue={(val) => {
                                        const update = { ...data };
                                        update[field.name] = val;
                                        setData(update)
                                    }} />
                                </Stack>
                            }
                            {field.type === "initials" &&
                                <Stack>
                                    <h4>{field.label} {field.required && <span style={{ color: 'indianred' }}>*</span>}</h4>
                                    <GenerateSignature name={data[field.signatureInputFieldKey]} useInitials setValue={(val) => {
                                        const update = { ...data };
                                        update[field.name] = val;
                                        setData(update)
                                    }} />
                                </Stack>
                            }
                            {(field.type === "date") &&
                                <>
                                    <h4>{field.label} {field.required && <span style={{ color: 'indianred' }}>*</span>}</h4>
                                    <DatePicker
                                        {...field}
                                        label={undefined}
                                        sx={{ width: '100%' }}
                                        value={data[field.name]}
                                        error={errorFields.includes(field.name)}
                                        onChange={(val) => {
                                            const update = { ...data };
                                            update[field.name] = val;
                                            setData(update)
                                        }}
                                    />
                                </>
                            }
                            {field.type === "checkbox" &&
                                <Stack display="flex" flexDirection="row" alignItems="center" alignContent={"center"}>
                                    <Checkbox {...field} value={data[field.name]} onChange={(e) => {
                                        const update = { ...data };
                                        update[field.name] = e.target.value;
                                        setData(update)
                                    }} />
                                    <h4>{field.label} {field.required && <span style={{ color: 'indianred' }}>*</span>}</h4>
                                </Stack>
                            }
                            {field.type === "select" &&
                                <>
                                    <h4>{field.label} {field.required && <span style={{ color: 'indianred' }}>*</span>}</h4>
                                    <Select fullWidth value={data[field.name]} onChange={(val) => {
                                        const update = { ...data };
                                        update[field.name] = val.target.value;
                                        setData(update)
                                    }} >
                                        {field.options.map((option, oIndex) => (
                                            <MenuItem key={`field-${index}-option-${oIndex}`} value={option.value} label={option.label}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </>
                            }
                            {
                                field.type === "file" &&
                                <>
                                    <h4>{field.label} {field.required && <span style={{ color: 'indianred' }}>*</span>}</h4>
                                    <Stack spacing={1}>
                                        <UploadMultiFile
                                            files={data[field.name]}
                                            sx={{ p: 0 }}
                                            onDrop={(files) => {
                                                if (files.length > 0){
                                                    const update = { ...data };
                                                    update[field.name] = [];
                                                    files.forEach((file) => {
                                                        update[field.name].push(file)
                                                    })
                                                    setData(update);
                                                }
                                            }}
                                            onRemove={(file) => {
                                                const update = { ...data };
                                                update[field.name] = update[field.name].filter(x => x.name !== file.name)
                                                setData(update);
                                            }}
                                            onRemoveAll={() => {
                                                const update = { ...data };
                                                update[field.name] = [];
                                                setData(update);
                                            }}
                                        />
                                        {/* {data[field.name] && <Label>{data[field.name].length} File(s) Attached ({data[field.name].map(x => x.name).join(', ')})</Label>} */}
                                        {field.helperText &&
                                            <Alert severity="warning">
                                                <AlertTitle>{field.helperTextHeader}</AlertTitle>
                                                {field.helperText}
                                            </Alert>
                                        }
                                    </Stack>
                                </>
                            }
                            {errorFields.includes(field.name) ? <Typography variant='caption' color={"error"}>This field is required</Typography> : <></>}
                        </Grid>
                    )
                })}
            </Grid>

            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleSubmit}>
                {config.submitButtonText || 'Next'}
            </LoadingButton>
        </Stack>
    );
}
