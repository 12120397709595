/* eslint-disable */
import PropTypes from 'prop-types';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, CardActionArea, Chip, Divider, Grid, Link, List, ListItem, ListSubheader, Popover, Stack, TextField, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// components
import { startCase } from 'lodash';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import Spinner from '../../components/Spinner';
import { IconButtonAnimate } from '../../components/animate';
import useAuth from '../../hooks/useAuth';
import hasRole from '../../utils/hasRole';
import { PUBLIC_DIRECTORY } from '../../routes/directory';

// ----------------------------------------------------------------------

const MotivationIllustration = lazy(() => import('../../assets/illustration_motivation'));

const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(5),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': {
    opacity: 0.48,
    textDecoration: 'none',
  },
}));

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...theme.typography.body2,
  padding: 0,
  marginTop: theme.spacing(3),
  color: theme.palette.text.secondary,
  transition: theme.transitions.create('color'),
  '&:hover': {
    color: theme.palette.text.primary,
  },
}));

// ----------------------------------------------------------------------

MenuDesktop.propTypes = {
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function MenuDesktop({ isOffset, isHome, navConfig }) {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [search, setSearch] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchOpen = () => {
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  const searchedPages = useMemo(() => {
    let results = [];
    if (!searchOpen || search === '') return PUBLIC_DIRECTORY;
    const lowerCaseSearchString = search.toLowerCase();
    return PUBLIC_DIRECTORY.filter(item => {
      const { label, keywords, description, tags, requiredRole } = item;

      let hasRequiredRole = !requiredRole ? true : false;
      if (requiredRole) {
        hasRequiredRole = hasRole(user, requiredRole);
      }

      // Convert label, description, and tags to lowercase for comparison
      const lowerCaseLabel = label.toLowerCase();
      const lowerCaseDescription = description.toLowerCase();

      // Check if the search string matches any part of the label, keywords, description, or tags
      return (
        (lowerCaseLabel.includes(lowerCaseSearchString) ||
          keywords.some(keyword => keyword.toLowerCase().includes(lowerCaseSearchString)) ||
          lowerCaseDescription.includes(lowerCaseSearchString)) &&
        hasRequiredRole
      );
    });
  }, [searchOpen, search])

  return (
    <Stack direction="row">
      {navConfig.map((link, index) => (
        <MenuDesktopItem
          key={`menu-desktop-item-${link.title}-${index}`}
          item={link}
          isOpen={open}
          onOpen={handleOpen}
          onClose={handleClose}
          isOffset={isOffset}
          isHome={isHome}
        />
      ))}
      <IconButtonAnimate onClick={handleSearchOpen} color="inherit" sx={{ marginRight: 2 }}>
        <Iconify icon={'eva:search-fill'} width={20} height={20} />
      </IconButtonAnimate>
      <Popover
        open={searchOpen}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 80, left: 0 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleSearchClose}
        PaperProps={{
          sx: {
            px: 1.5,
            py: 2,
            right: 16,
            m: 'auto',
            borderRadius: 2,
            maxWidth: (theme) => theme.breakpoints.values.lg,
            boxShadow: (theme) => theme.customShadows.z24,
            overflow: 'hidden',
          },
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <TextField
              InputProps={{
                startAdornment: <Iconify icon={'eva:search-fill'} width={20} height={20} sx={{mr: 1.5}}/>
              }}
              autoFocus
              size='small'
              placeholder='Search for pages & content throughout the website...'
              fullWidth
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            {searchedPages.length > 0 &&
              <>
                <Scrollbar sx={{ maxHeight: 420, width: '100%', px: 1.5 }}>
                  {searchedPages.map((page, index) => (
                    <>
                      <SearchItem item={page} />
                      {index !== searchedPages.length - 1 && <Divider/>}
                    </>
                  ))}
                </Scrollbar>
              </>
            }
          </Grid>
        </Grid>
      </Popover>
    </Stack>
  );
}

// ----------------------------------------------------------------------

IconBullet.propTypes = {
  type: PropTypes.oneOf(['item', 'subheader']),
};

function IconBullet({ type = 'item' }) {
  return (
    <Box sx={{ width: 24, height: 16, display: 'flex', alignItems: 'center' }}>
      <Box
        component="span"
        sx={{
          ml: '2px',
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'currentColor',
          ...(type !== 'item' && { ml: 0, width: 8, height: 2, borderRadius: 2 }),
        }}
      />
    </Box>
  );
}

// ----------------------------------------------------------------------

MenuDesktopItem.propTypes = {
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.array,
  }),
};

function MenuDesktopItem({ item, isHome, isOpen, isOffset, onOpen, onClose }) {
  const { title, path, children } = item;

  if (children) {
    return (
      <>
        <LinkStyle
          onClick={(e) => {
            e.preventDefault();
            onOpen()
          }}
          href={path}
          sx={{
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            color: 'white',
            ...(isOffset && { color: 'text.primary' }),
            ...(isOpen && { opacity: 0.48 }),
          }}
        >
          {title}
          <Iconify
            icon={isOpen ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
            sx={{ ml: 0.5, width: 16, height: 16 }}
          />
        </LinkStyle>

        <Popover
          open={isOpen}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 80, left: 0 }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={onClose}
          PaperProps={{
            sx: {
              px: 3,
              pb: 3,
              right: 16,
              m: 'auto',
              borderRadius: 2,
              maxWidth: (theme) => theme.breakpoints.values.lg,
              boxShadow: (theme) => theme.customShadows.z24,
            },
          }}
        >
          <Grid container spacing={3}>
            {children.map((list, index) => {
              const { subheader, items } = list;

              return (
                <Grid key={`menu-item-${subheader}-${index}`} item xs={12} md={subheader === 'Dashboard' ? 6 : 4}>
                  <List disablePadding>
                    {subheader &&
                      <ListSubheader
                        disableSticky
                        disableGutters
                        sx={{
                          display: 'flex',
                          lineHeight: 'unset',
                          alignItems: 'center',
                          color: 'text.primary',
                          typography: 'overline',
                        }}
                      >
                        <IconBullet type="subheader" /> {subheader}
                      </ListSubheader>
                    }

                    {items.map((item) => (
                      <ListItemStyle
                        key={item.title}
                        to={item.path}
                        component={RouterLink}
                        underline="none"
                        sx={{
                          '&.active': {
                            color: 'text.primary',
                            typography: 'subtitle2',
                          },
                        }}
                      >
                        {item.title === 'Presentations Available' ? (
                          <CardActionArea
                            sx={{
                              py: 3,
                              px: 10,
                              borderRadius: 2,
                              bgcolor: 'background.neutral',
                            }}
                          >
                            <Stack>
                              <Typography variant='caption' textAlign="center" sx={{ width: '100%', mb: 2 }}><strong>{item.title}</strong></Typography>
                              <Box
                                whileTap="tap"
                                whileHover="hover"
                                variants={{
                                  hover: { scale: 1.02 },
                                  tap: { scale: 0.98 },
                                }}
                              >
                                <Suspense fallback={<Spinner />}>
                                  <MotivationIllustration />
                                </Suspense>
                              </Box>
                            </Stack>
                          </CardActionArea>
                        ) : (
                          <>
                            <IconBullet />
                            {item.title}
                          </>
                        )}
                      </ListItemStyle>
                    ))}
                  </List>
                </Grid>
              );
            })}
          </Grid>
        </Popover>
      </>
    );
  }

  if (title === 'Documentation') {
    return (
      <LinkStyle
        href={path}
        target="_blank"
        rel="noopener"
        sx={{
          ...(isHome && { color: 'common.white' }),
          ...(isOffset && { color: 'text.primary' }),
        }}
      >
        {title}
      </LinkStyle>
    );
  }

  return (
    <LinkStyle
      to={path}
      component={RouterLink}
      end={path === '/'}
      sx={{
        color: 'white',
        ...(isOffset && { color: 'text.primary' }),
        '&.active': {
          color: 'primary.main',
        },
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {title}
    </LinkStyle>
  );
}

function SearchItem({ item }) {
  const theme = useTheme();
  return (
    <LinkStyle
      href={item.path}
      sx={{
        my: 1,
        width: '100%',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        fontSize: 18,
        opacity: '1 !important',
        ':hover': {
          color: theme.palette.primary.light
        }
      }}
    >
        <Stack>
          <Typography variant='h6'>{item.label}</Typography>
          <Typography variant='body2' style={{ color: theme.palette.text.secondary }}>{item.description}</Typography>
          <Stack direction="row" flexWrap="wrap" sx={{ mt: 1 }}>
            {item.keywords.map((keyword) => <Chip label={keyword} size="small" variant='outlined' sx={{ mr: 0.5, mb: 0.5 }} />)}
          </Stack>
        </Stack>
    </LinkStyle>
  )
}
