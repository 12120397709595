import { AnimatePresence, m } from 'framer-motion';
import { useEffect } from 'react';
// @mui
import { Divider, Stack, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// hooks
import useSettings from '../../hooks/useSettings';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { NAVBAR } from '../../config';
//
import Iconify from '../Iconify';
import { IconButtonAnimate, varFade } from '../animate';
import OnlineUsers from './OnlineUsers';

// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ blur: 32, color: theme.palette.background.paper, opacity: 0.5 }),
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  position: 'fixed',
  overflow: 'hidden',
  height: '100%',
  width: 400,
  flexDirection: 'column',
  zIndex: theme.zIndex.drawer + 3,
  boxShadow: `-24px 12px 32px -4px ${alpha(
    theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
    0.16
  )}`,
}));

// ----------------------------------------------------------------------

export default function OnlineUsersPopover({ open, setOpen }) {
  const { themeDirection, onResetSetting } = useSettings();

  const varSidebar =
    themeDirection !== 'rtl'
      ? varFade({
        distance: NAVBAR.BASE_WIDTH,
        durationIn: 0.32,
        durationOut: 0.32,
      }).inRight
      : varFade({
        distance: NAVBAR.BASE_WIDTH,
        durationIn: 0.32,
        durationOut: 0.32,
      }).inLeft;

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  return (
    <>
      <AnimatePresence>
        {open && (
          <>
            <RootStyle {...varSidebar}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5 }}>
                <Typography variant="subtitle1">Online Users</Typography>
                <IconButtonAnimate onClick={() => setOpen((prev) => !prev)}>
                  <Iconify icon={'eva:close-fill'} width={20} height={20} />
                </IconButtonAnimate>
              </Stack>

              <Divider sx={{ borderStyle: 'dashed' }} />

              <OnlineUsers />
            </RootStyle>
          </>
        )}
      </AnimatePresence>
    </>
  );
}
