
/* eslint-disable */
import React, { useEffect, useState } from 'react';
// @mui
import { Box, Button, Dialog, Divider, Menu, MenuItem, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import cssStyles from '../../utils/cssStyles';
// components
import { useSnackbar } from 'notistack';
import { ServerUtils } from '../../api_utils';
import useSettings from '../../hooks/useSettings';
import useSocket from '../../hooks/useSocket';
import UserProfile from '../../pages/dashboard/UserProfile';
import createAvatar from '../../utils/createAvatar';
import truncateString from '../../utils/truncateString';
import Avatar from '../Avatar';
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import Spinner from '../Spinner';
import { useNavigate } from 'react-router';
const semver = require('semver');

// ----------------------------------------------------------------------

const notificationTypes = ["primary", "secondary", "success", "info", "error", "warning"]
const initNotification = {
  type: "primary",
  title: "",
  description: "",
  canClose: true,
}

const roleColors = {
  "developer": "error",
  "admin": "primary",
  "manager": "primary",
  "employee": "warning",
  "referral": "info",
  "user": "success",
}
const msToTime = (ms) => {
  const seconds = (ms / 1000).toFixed(0);
  const minutes = (ms / (1000 * 60)).toFixed(2);
  const hours = (ms / (1000 * 60 * 60)).toFixed(2);
  const days = (ms / (1000 * 60 * 60 * 24)).toFixed(2);
  let result = `${days} days`
  if (seconds < 60) result = `${seconds} sec(s)`;
  else if (minutes < 60) result = `${minutes} min(s)`;
  else if (hours < 24) result = `${hours} hr(s)`;
  return result
}


export default function OnlineUsers() {
  const { enqueueSnackbar } = useSnackbar()
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [userProfileOpen, setUserProfileOpen] = useState(false);
  const [notification, setNotification] = useState(initNotification);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const { socket, connected } = useSocket();

  const onSendNotification = async () => {
    if (socket) {
      socket.emit('send_global_notification', notification);
    } else {
      enqueueSnackbar('You are not connected to the server!', { variant: 'error' })
    }
  }

  useEffect(() => {
    if (socket) {
      // setup live users list updater
      socket.on('update_live_users', (data) => {
        setUsers(data);
      })
      // request to join live users subscription
      socket.emit('receive_live_users');
      // global notification confirmation
      socket.on('confirm_global_notification', (data) => {
        enqueueSnackbar(`Notification sent to ${data.notified} users`);
      })
    }
  }, [socket])

  useEffect(() => {
    return () => {
      if (socket) {
        socket.emit('stop_receiving_live_users')
      }
    };
  }, [])

  return (
    <>
      <Scrollbar flexGrow={1}>
        {users.length === 0 ?
          <Spinner />
          :
          <Stack spacing={1}>
            {users.map((user, index) =>
              <OnlineUserItem
                key={`online-user-${index}`}
                user={user}
                setSelectedUser={setSelectedUser}
                setUserProfileOpen={setUserProfileOpen}
                socket={socket}
              />
            )}
          </Stack>
        }
      </Scrollbar>
      <Button
        disabled={users.length === 0}
        color='primary'
        size='large'
        fullWidth
        sx={{ borderRadius: 0 }}
        onClick={(e) => {
          setNotification(initNotification);
          setNotificationOpen(true);
        }}
      >
        Notify All
      </Button>
      <Dialog open={notificationOpen} onClose={(e) => setNotificationOpen(false)} fullWidth maxWidth="sm">
        <Typography sx={{ p: 2 }} >Notify Online Users</Typography>
        <Divider />
        <Stack sx={{ p: 2 }} spacing={2}>
          <TextField
            label="Title"
            variant='outlined'
            value={notification.title}
            onChange={(e) => setNotification({ ...notification, title: e.target.value })}
          />
          <TextField
            label="Description/Message"
            variant='outlined'
            value={notification.description}
            onChange={(e) => setNotification({ ...notification, description: e.target.value })}
            multiline
            rows={3}
          />
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Select
              value={notification?.type}
              placeholder='Select Type'
              onChange={(event) => {
                setNotification({ ...notification, type: event.target.value })
              }}
            >
              {notificationTypes.map(g => (
                <MenuItem value={g}>{g}</MenuItem>
              ))}
            </Select>
            <Stack direction="row" alignItems="center" justifyContent="end">
              <Typography variant='caption'>Can close</Typography>
              <Switch checked={notification.canClose} onChange={(e) => setNotification({ ...notification, canClose: e.target.checked })} />
            </Stack>
          </Stack>
          <Button variant='contained' color={notification.type} onClick={onSendNotification}>Send</Button>
        </Stack>
      </Dialog>
      <Dialog open={userProfileOpen} onClose={(e) => setUserProfileOpen(false)} fullWidth maxWidth="md">
        {selectedUser && <UserProfile userId={selectedUser?.id} />}
      </Dialog>
    </>
  );
}

function OnlineUserItem({ user, index, setSelectedUser, setUserProfileOpen, socket }) {
  const theme = useTheme()
  const { themeMode } = useSettings();
  const [contextMenu, setContextMenu] = useState(null);
  const navigate = useNavigate()

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
        // Other native context menus might behave different.
        // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
        null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const getClientVersionDetails = (instance) => {
    const res = {
      version: 'NA',
      color: 'white'
    }
    if (instance.client_version) {
      res.version = instance.client_version;
      res.color = semver.lt(instance.client_version, ServerUtils.getVersion()) ? "indianred" : 'lawngreen';
    }
    return res;
  }

  return (
    <Box
      key={`user-${index}`}
      spacing={1}
      sx={{
        cursor: 'pointer',
        transition: '.1s',
        '&:hover': {
          ...cssStyles(theme).bgBlur({ blur: 32, color: alpha(theme.palette.grey[themeMode === "dark" ? 800 : 200], .5), opacity: 0.5 }),
        }
      }}>
      <Stack sx={{ p: 1 }} spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
          onClick={() => {
            setSelectedUser(user)
            setUserProfileOpen(true);
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={ServerUtils.getUserImageUrl(user)}
              alt={user?.username}
              color={roleColors[user?.role]}
              sx={{
                width: 36,
                height: 36
              }}
            >
              {createAvatar(user?.name).name}
            </Avatar>
            <Stack sx={{ ml: 1 }}>
              <Typography variant="subtitle2" sx={{ m: 0 }}>{truncateString(user?.name, 15)} </Typography>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                {user?.id} - {truncateString(user?.username, 30)}
              </Typography>
            </Stack>
          </Box>
          <Stack>
            <Label
              sx={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
              color={roleColors[user?.role]}
            >{user?.role}</Label>
            <SessionTimer last_login={user?.last_login} />
          </Stack>
        </Stack>
        <Stack spacing={1}>
          {(user?.instances && user?.instances.length > 0) && user?.instances.map((instance, index) => {
            const versionDetails = getClientVersionDetails(instance);
            return (
              <>
                <Label key={`user-${user?.id}-instance-${index}`} onContextMenu={handleContextMenu} style={{ cursor: 'pointer' }} onClick={() => navigate(instance.current_page)}>
                  <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
                    <Typography variant='caption'><strong>#{index + 1}&nbsp;</strong>[<strong><Typography variant='caption' sx={{color: versionDetails.color}}>{versionDetails.version}</Typography></strong>]</Typography>
                    <Typography variant='caption' sx={{ color: 'text.secondary' }}><strong>{instance.current_page || "?"}</strong></Typography>
                  </Stack>
                </Label>
                <Menu
                  open={contextMenu !== null}
                  onClose={handleClose}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    contextMenu !== null
                      ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                      : undefined
                  }
                >
                  <MenuItem onClick={() => {
                    handleClose()
                    if (socket) {
                      socket.emit('refresh-page', instance)
                    }
                  }}>Refresh Page</MenuItem>
                  <MenuItem onClick={() => {
                    handleClose()
                    if (socket) {
                      socket.emit('reconnect-socket', instance)
                    }
                  }}>Reconnect</MenuItem>
                  <MenuItem onClick={() => {
                    handleClose()
                    if (socket) {
                      socket.emit('disconnect-socket', instance)
                    }
                  }}>Disconnect</MenuItem>
                </Menu>
              </>
            )
          })}
        </Stack>
      </Stack>
      <Divider />
    </Box>
  )
}

function SessionTimer({ last_login }) {
  const [sessionTimeMs, setSessionTimeMs] = useState(new Date().getTime() - new Date(last_login).getTime());

  useEffect(() => {
    var intervalId = setInterval(() => {
      setSessionTimeMs(new Date().getTime() - new Date(last_login).getTime())
    }, 5000)
    return () => {
      clearInterval(intervalId)
    };
  }, [])


  return (
    <Label
      sx={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
    >{msToTime(sessionTimeMs)}</Label>
  )
}