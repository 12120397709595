
export default class ServerUtils {

  static getVersion() {
    return "1.0.9";
  }

  static fileSystemDownloadEndpoint() {
    if (window.location.hostname.includes("localhost")) {
      return "http://localhost:3002/"
    }
    return "https://api.steincounseling.org/"
  }

  static apiEndpoint() {
    if (window.location.hostname.includes("localhost")) {
      return "/"
    }
    return "https://api.steincounseling.org/"
  }

  static getUserImageUrl(user) {
    if (user && user.imageUrl && user.imageUrl.length > 0) {
      if (window.location.hostname.includes("localhost")) {
        return `http://localhost:3002/data/public/images/avatars/users/${user?.imageUrl}`
      }
      return `https://api.steincounseling.org/data/public/images/avatars/users/${user?.imageUrl}`
    }
    return ""
  }

  static getUserBannerUrl(user) {
    if (user && user.bannerUrl && user.bannerUrl.length > 0) {
      if (user.bannerUrl.includes("http")) {
        return user.bannerUrl;
      }
      if (window.location.hostname.includes("localhost")) {
        return `http://localhost:3002/data/public/images/banners/users/${user?.bannerUrl}`
      }
      return `https://api.steincounseling.org/data/public/images/banners/users/${user?.bannerUrl}`
    }
    return 'https://steincounseling.org/img/default-banner.jpg'
  }

  static getPostImageUrl(post) {
    if (post && post.imageUrl && post.imageUrl.length > 0) {
      if (post.imageUrl.includes("http")) {
        return post.imageUrl;
      }
      if (window.location.hostname.includes("localhost")) {
        return `http://localhost:3002/data/public/images/posts/blog/${post.imageUrl}`
      }
      return `https://api.steincounseling.org/data/public/images/posts/blog/${post?.imageUrl}`
    }
  }

  static async POST(url, body) {
    return new Promise((resolve, reject) => {
      fetch(this.apiEndpoint() + url, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }).then((response) => resolve(response.json()))
        .catch((error) => {
          console.error("POST error: ", error)
          reject(error);
        });
    });
  }

  static async GET(url) {
    return new Promise((resolve, reject) => {
      fetch(this.apiEndpoint() + url, {
        credentials: 'include',
      })
        .then((response) => resolve(response.json()))
        .catch((error) => {
          console.error("GET error: ", error)
          reject(error);
        });
    });
  }

  static async sendMessage(msg) {
    return this.POST('api/messages/send', { message: msg })
  }

  static async deleteMessage(msg) {
    return this.POST('api/messages/delete', { message: msg })
  }

  static async unlockRequest(password) {
    return this.POST('unlock', { password })
  }

  static async recentEventsSummary(){
    return this.GET('api/reports/recent-events-summary')
  }
}