import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// @mui
import { Box, CircularProgress, Divider, Drawer, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// hooks
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useResponsive from '../../../hooks/useResponsive';

// utils
import cssStyles from '../../../utils/cssStyles';

// config
import { NAVBAR } from '../../../config';

// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
import { ServerUtils } from '../../../api_utils';
import Label from '../../../components/Label';
import useAuth from '../../../hooks/useAuth';
import useSocket from '../../../hooks/useSocket';
import CollapseButton from './CollapseButton';
import navConfig from './NavConfig';
import { PATH_DASHBOARD } from '../../../routes/paths';

const semver = require('semver');

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const { user } = useAuth()
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();
  const [navConfigFilled, setNavConfigFilled] = useState(navConfig(user.id, -1))
  const { serverPlatformVersion } = useSocket();
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ maxHeight: 48, overflow: 'hidden' }}>
          <Stack direction="row" alignItems="center" >
            <Logo />
            {!isCollapse &&
              <Typography variant='subtitle2' sx={{ fontWeight: 900, ml: 1, }}>Stein Counseling & Consulting Services</Typography>
            }
          </Stack>

          {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          )}
        </Stack>
      </Stack>
      <Divider />

      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        }}
      >
        <NavSectionVertical navConfig={navConfigFilled} isCollapse={isCollapse} />

      </Scrollbar>
      {isDesktop && !isCollapse && (
        <>
          <Stack direction="row" justifyContent="space-evenly" alignContent="center" onClick={() => navigate(PATH_DASHBOARD.changelog)} sx={{
            cursor: 'pointer',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99,
          }}>
            <Label color='primary' sx={{ width: '50%', borderRadius: 0, py: 2, cursor: 'pointer', color: 'text.secondary', backgroundColor: 'primary.darker', ":hover": { color: 'white', backgroundColor: 'primary.dark' } }}>Website v{ServerUtils.getVersion()}</Label>
            <Label color='primary' sx={{ width: '50%', borderRadius: 0, py: 2, cursor: 'pointer', color: 'text.secondary', backgroundColor: 'primary.darker', ":hover": { color: 'white', backgroundColor: 'primary.dark' } }}>Server v{serverPlatformVersion}</Label>
          </Stack>
        </>
      )}
    </>

  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
