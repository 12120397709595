import PropTypes from 'prop-types';
// @mui
import { Divider, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

ProfileAbout.propTypes = {
  profile: PropTypes.object,
};

export default function ProfileAbout({ profile }) {

  return (
    <Stack spacing={2} sx={{ p: 3 }}>

      {profile.user.position &&
        <Stack direction="row" alignContent="center" alignItems="center">
          <Typography variant="h5">Position</Typography>
          <>&nbsp;-&nbsp;</>
          <Typography variant="body2">{profile.user.position}</Typography>
        </Stack>
      }

      <Stack direction="row">
        <IconStyle icon={'eva:email-fill'} />
        <Typography variant="body2">{profile.user.username}</Typography>
      </Stack>

      <Typography variant="h5">About {profile.user.name}</Typography>
      <Divider />
      <div dangerouslySetInnerHTML={{ __html: profile.user.about }} />

      {profile.user.city && profile.user.state && profile.user.country &&
        <Stack direction="row">
          <IconStyle icon={'eva:pin-fill'} />
          <Typography variant="body2">
            Lives in &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              {profile.user.city}, {profile.user.state} {profile.user.country}
            </Link>
          </Typography>
        </Stack>
      }

      {/* <Stack direction="row">
          <IconStyle icon={'ic:round-business-center'} />
          <Typography variant="body2">
            {role} at &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              {company}
            </Link>
          </Typography>
        </Stack> */}

      {/* <Stack direction="row">
          <IconStyle icon={'ic:round-business-center'} />
          <Typography variant="body2">
            Studied at &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              {school}
            </Link>
          </Typography>
        </Stack> */}
    </Stack>
  );
}
