/* eslint-disable */
import ServerUtils from "./helpers";

export default class AccountUtils {
  static async attemptLogin(email, password) {
    return ServerUtils.POST('api/account/login', { email, password })
  }
  static async attemptLogout() {
    return ServerUtils.POST('api/account/logout')
  }
  static async attemptRegister(email, password, name, isReferral = false) {
    return ServerUtils.POST('api/account/register', { email, password, name, isReferral })
  }
  static async changePassword(user, newPassword) {
    return ServerUtils.POST('api/account/change-password', { user, newPassword, })
  }
  static async requestPasswordReset(email) {
    return ServerUtils.POST('api/account/request-reset-password', { email })
  }
  static async validatePasswordResetToken(token) {
    return ServerUtils.POST('api/account/check-password-token', { token })
  }
  static async submitPasswordReset(token, newPassword) {
    return ServerUtils.POST('api/account/submit-reset-password', { token, newPassword })
  }
}