/* eslint-disable */
import ServerUtils from "./helpers";

export default class AdminUtils {
  static async getAll() {
    return await ServerUtils.GET('api/admin/')
  }
  static async getProviderSatisfactionReportData(startDate, endDate) {
    return await ServerUtils.POST('api/reports/client-satisfaction-averages', {
      startDate,
      endDate
    })
  }
  static async getCourseCompletionReportData(startDate, endDate){
    return await ServerUtils.POST('api/reports/course-completion', {
      startDate,
      endDate
    })
  }
  static async sendEmail(email){
    return await ServerUtils.POST('api/email/send/custom', {email})
  }
  static async getBlacklist(){
    return await ServerUtils.GET('api/email/blacklist/get-all')
  }
  static async addBlacklistEmail(email){
    return await ServerUtils.POST('api/email/blacklist/add', {email})
  }
  static async deleteBlacklistEmail(email){
    return await ServerUtils.POST('api/email/blacklist/remove', {email})
  }
}