// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import Label from '../../../components/Label';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  developer: getIcon('ic_developer'),
  admin: getIcon('ic_admin'),
  user: getIcon('ic_user'),
  profile: getIcon('ic_profile'),
  messages: getIcon('ic_messages'),
  settings: getIcon('ic_settings'),
  calendar: getIcon('ic_calendar'),
  home: getIcon('ic_home'),

  search: getIcon('ic_search'),
  checklist: getIcon('ic_checklist'),
  clipboard: getIcon('ic_clipboard'),
  briefcase: getIcon('ic_briefcase'),
  clipboardArrow: getIcon('ic_clipboard_arrow'),
  clipboardPlus: getIcon('ic_clipboard_plus'),
};

const navConfig = (userId, notificationCount = 0) => {
  return [
    // GENERAL
    // ----------------------------------------------------------------------
    
    {
      subheader: 'home',
      items: [
        {
          title: 'Dashboard',
          path: PATH_DASHBOARD.home.root,
          icon: ICONS.home,
        },
        {
          title: 'Courses',
          path: PATH_DASHBOARD.home.courses,
          icon: ICONS.checklist,
        },
      ],
    },
    {
      subheader: 'My Account',
      items: [
        {
          title: 'Administration',
          path: PATH_DASHBOARD.admin.root,
          icon: ICONS.admin,
          role: "manager",
          children: [
            { title: 'Overview', path: PATH_DASHBOARD.admin.overview, role: "manager" },
            { title: 'Management', path: PATH_DASHBOARD.admin.management, role: "manager" },
            { title: 'Course Factory', path: PATH_DASHBOARD.admin.courseFactory, role: "manager" },
            { title: 'Reports', path: PATH_DASHBOARD.admin.reports, role: "manager" },
            { title: 'File Manager', path: PATH_DASHBOARD.admin.fileManager, role: "admin" },
            { title: 'Server', path: PATH_DASHBOARD.admin.administration, role: "admin" },
          ],
        },
        {
          title: 'Referral',
          path: PATH_DASHBOARD.referral.root,
          icon: ICONS.clipboardArrow,
          role: "referral",
          children: [
            { title: 'My Referrals', path: PATH_DASHBOARD.referral.myReferrals, role: "referral", exactRole: true },
            { title: 'Create Referral', path: PATH_DASHBOARD.referral.createReferral, role: "referral", },
          ],
        },
        {
          title: 'Employee',
          path: PATH_DASHBOARD.employee.root,
          icon: ICONS.clipboard,
          role: "employee",
          exactRole: true,
          children: [
            { title: 'Submissions', path: PATH_DASHBOARD.employee.submissions, role: "employee" },
          ],
        },
        {
          title: 'Developer',
          path: PATH_DASHBOARD.developer.root,
          icon: ICONS.developer,
          role: "developer",
          children: [
            { title: 'kanban', path: PATH_DASHBOARD.developer.kanban},
          ],
        },
        // { 
        //   title: 'Messages', 
        //   path: PATH_DASHBOARD.user.mail, 
        //   info: (<Label color="primary">{notificationCount}</Label>),
        //   icon: ICONS.messages,
        // },
        { 
          title: 'Settings', 
          path: PATH_DASHBOARD.user.account,
          icon: ICONS.settings,
        },
      ],
    },
  ];
}

export default navConfig;
