import { useEffect, useState } from 'react';
// @mui
import { Box, Stack, Typography } from '@mui/material';
import { UserUtils } from '../../api_utils';
// routes
// hooks
import useSettings from '../../hooks/useSettings';
// components
// sections
import LogoSpinner from '../../components/LogoSpinner';
import Scrollbar from '../../components/Scrollbar';
import {
  Profile,
  ProfileCover
} from '../../sections/@dashboard/user/profile';

export default function UserProfile({ userId }) {
  const { themeStretch } = useSettings();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(undefined);

  const loadProfile = async () => {
    setLoading(true);
    const res = await UserUtils.getProfile(userId);
    if (res.success) {
      setProfile(res.data);
    }
    setLoading(false)
  }

  useEffect(() => {
    console.log(userId)
    loadProfile()
  }, [userId])

  return (
    // <ComingSoon date={'01/01/2024 21:30'}/>
    <>
      {loading ?
        <Stack textAlign="center" sx={{ pt: 5 }}>
          <LogoSpinner />
          <Typography variant='h4' color="primary.dark" >Loading Profile...</Typography>
        </Stack>
        :
        <>
          {profile ?
            <>
              <Box
                sx={{
                  height: 180,
                  position: 'relative',
                }}
              >
                <ProfileCover user={profile.user} />
              </Box>

              <Scrollbar sx={{ maxHeight: 500, pb: 3 }}>
                <Profile profile={profile} />
              </Scrollbar>
            </>
            :
            <Box sx={{p: 5}}>
              <Typography variant='h5' textAlign="center">Profile Not Found!</Typography>
            </Box>
          }

        </>
      }
    </>
  );
}
