/* eslint-disable */
import ServerUtils from "./helpers";

export default class FileUtils {
  static async getFileSystem() {
    return ServerUtils.GET(`api/files/file-system`)
  }
  static async getFile(fileId) {
    return ServerUtils.GET(`api/files/file-system/file/${fileId}`)
  }
  static async uploadFile(data) {
    return new Promise((resolve, reject) => {
        console.log('FormData:', data); // Log FormData object
        fetch(`${ServerUtils.apiEndpoint()}api/files/file-system/upload`, {
            method: 'POST',
            credentials: 'include',
            body: data,
        }).then((response) => {
            console.log('Response:', response); // Log response
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).then((data) => {
            console.log('Response Data:', data); // Log response data
            resolve(data);
        }).catch((error) => {
            console.error("POST error: ", error)
            reject(error);
        });
    });
}
  static async fixFiles(data) {
    return ServerUtils.POST(`api/files/file-system/fix-missing-files`, {data: data})
  }
  static async createBackup() {
    return ServerUtils.POST(`api/files/file-system/create-backup`)
  }
  static async deleteFile(data) {
    return ServerUtils.POST(`api/files/file-system/delete/file`, {data: data})
  }
  static async deleteFolder(path) {
    return ServerUtils.POST(`api/files/file-system/delete/folder`, {path: path})
  }
}