/* eslint-disable */
import PropTypes from 'prop-types';
// @mui
import { Card, CardHeader, Link, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

ProfileSocialInfo.propTypes = {
  user: PropTypes.object,
};

export default function ProfileSocialInfo({ user }) {
  const { facebook_link, instagram_link, linkedin_link, twitter_link } = user;

  const SOCIALS = [
    {
      name: 'Linkedin',
      icon: <IconStyle icon={'eva:linkedin-fill'} color="#006097" />,
      href: linkedin_link,
    },
    {
      name: 'Twitter',
      icon: <IconStyle icon={'eva:twitter-fill'} color="#1C9CEA" />,
      href: twitter_link,

    },
    {
      name: 'Instagram',
      icon: <IconStyle icon={'ant-design:instagram-filled'} color="#D7336D" />,
      href: instagram_link,
    },
    {
      name: 'Facebook',
      icon: <IconStyle icon={'eva:facebook-fill'} color="#1877F2" />,
      href: facebook_link,
    },
  ];

  return (
    <Card variant="outlined" sx={{ borderRadius: 1, boxShadow: 'none', height: '100%' }}>
      <CardHeader title="Socials" />
      <Stack spacing={2} sx={{ p: 3 }}>
        {SOCIALS.map((link) => (
          <>
            <Stack key={link.name} direction="row" alignItems="center">
              {link.icon}
              {link.href ?
                <Link component="a" variant="body2" color="text.primary" noWrap target="_blank" href={link.href}>
                  {link.href}
                </Link>
                :
                <>N/A</>
              }
            </Stack>
          </>
        ))}
      </Stack>
    </Card>
  );
}
