import { useEffect, useState } from "react";
import AnimatedNumber from 'react-animated-number';

export default function NumberAnimate({ value, formatter, duration = 1000, stepPrecision = 3 }) {
    const [number, setNumber] = useState(value);

    useEffect(() => {
        setNumber(prev => {
            const diff = value - number;
            return prev + diff
        })
    }, [value])

    return (
        <AnimatedNumber
            value={number}
            duration={duration}
            formatValue={formatter}
            frameStyle={percentage => percentage > 20 && percentage < 80 ? { opacity: 0.8 } : {}}
            stepPrecision={stepPrecision}
        />
    )
}