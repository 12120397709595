import { format, getTime, formatDistanceToNow } from 'date-fns';
import fixDate from './fixDate';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(fixDate(new Date(date)), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(fixDate(new Date(date)), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(fixDate(new Date(date)));
}

export function fDateTimeSuffix(date) {
  return format(fixDate(new Date(date)), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(fixDate(new Date(date)), {
    addSuffix: true
  });
}
