import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import { ServerUtils } from '../api_utils';
import { isValidToken, setSession, sign } from '../utils/jwt';
import AccountUtils from '../api_utils/account';
import { PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SET_USER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  setUser: () => Promise.resolve(),
});

const JWT_SECRET = 'F4A26AE48E7456D2FCF4A6757F725';
const REFRESH_SECRET = '4E37E7B3E76817681AFB92A45AA5C';
const JWT_EXPIRES_IN = '364d';
const JWT_REFRESH_EXPIRES_IN = '60s';

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        let accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const user = JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString());
          delete user.exp;
          delete user.iat;

          accessToken = sign(user, JWT_SECRET);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await AccountUtils.attemptLogin(email, password);
    if (!response.success){
      throw new Error([400, { message: 'There is no user corresponding to the email address.' }]);
    }
    
    const { success, message, user } = response.data;
    const accessToken = sign(user, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const register = async (email, password, name, skipSessionSet = false, isReferral = false) => {
    const response = await AccountUtils.attemptRegister(email, password, name, isReferral)
    if (!response.success){
      throw new Error([400, { message: 'A user with that email already exists!' }]);
    }
    const { success, message, user } = response.data;
    if (!skipSessionSet) {
      const accessToken = sign({ userId: user.id }, JWT_SECRET, {
        expiresIn: JWT_EXPIRES_IN
      });
      window.localStorage.setItem('accessToken', accessToken);
      dispatch({
        type: 'REGISTER',
        payload: {
          user,
        },
      });
    }
  };

  const logout = async () => {
    const res = await AccountUtils.attemptLogout();
    if (res.success){
      setSession(null);
      dispatch({ type: 'LOGOUT' });
    }
  };

  const setUser = (user) => {
    const accessToken = sign(user, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });
    setSession(accessToken);
    dispatch({
      type: 'SET_USER',
      payload: {
        user,
      },
    });
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        setUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
