export default function fixDate(date) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}
export function fixDateTime (date) {
    const currentDate = fixDate(new Date(date.getTime()));
    const utcDate = new Date(
        Date.UTC(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            currentDate.getHours(),
            currentDate.getMinutes(),
            currentDate.getSeconds(),
        ),
    );
    return utcDate;
};
