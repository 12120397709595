// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets
import { Suspense, lazy } from 'react';
import Spinner from '../Spinner';

// ----------------------------------------------------------------------

const UploadIllustration = lazy(() => import('../../assets/illustration_upload'));

export default function BlockContent({mode = "single"}) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, height: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <Suspense fallback={<Spinner />}>
        <UploadIllustration sx={{ width: 128 }} />
      </Suspense>

      <Box >
        <Typography gutterBottom variant="h5">
          Drop or Select file{mode = "multiple" ? "s" : ""}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Drop file{mode = "multiple" ? "s" : ""} here or click&nbsp;
          <Typography
            variant="body2"
            component="span"
            sx={{ color: 'primary.main', textDecoration: 'underline' }}
          >
            browse
          </Typography>
        </Typography>
      </Box>
    </Stack>
  );
}
