import Iconify from "../Iconify";
import { IconButtonAnimate } from "../animate";


export default function OnlineUsersToggle({ open, onToggle }) {
    return (
        <IconButtonAnimate
            color="default"
            onClick={onToggle}
            sx={{
                transition: (theme) => theme.transitions.create('all')
            }}
        >
            <Iconify icon="mdi:users" width={20} height={20} />
        </IconButtonAnimate>
    )
}