
// @mui
import { Button, Grid, Stack } from '@mui/material';
//
import { useNavigate } from 'react-router';
import { PATH_PAGE } from '../../../../routes/paths';
import ProfileAbout from './ProfileAbout';

export default function Profile({ profile }) {
  const navigate = useNavigate();
  return (
    <Grid container spacing={1} sx={{ p: 1 }}>
      <Grid item xs={12} md={12}>
        <ProfileAbout profile={profile} />
      </Grid>
      {(profile.user.role !== "user" && profile.user.role !== "referral") &&
        <Grid item xs={12} md={12}>
          <Stack direction="row" sx={{ mt: 1, px: 3 }}>
            <Button onClick={() => navigate(PATH_PAGE.contact)} variant="contained">
              Request an appointment or get more information by clicking here
            </Button>
          </Stack>
          {/* <ProfileSocialInfo user={profile.user} /> */}
        </Grid>
      }
    </Grid>
  );
}
