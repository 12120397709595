/* eslint-disable */
import ServerUtils from "./helpers";

export default class KanbanUtils {
  static async getKanban() {
    return ServerUtils.GET('api/kanban/')
  }
  static async updateKanban(kanban) {
    return ServerUtils.POST('api/kanban/update', {kanban: kanban});
  }
}