import { Button, Stack } from '@mui/material';
import { toPng } from 'html-to-image';
import { useEffect, useState } from 'react';
import '../assets/signature.css'; // Import CSS file with signature font

export const GenerateSignature = ({ name, useInitials = false, setValue }) => {
    const [signatureUrl, setSignatureUrl] = useState('');
    const [text, setText] = useState(name)

    const generateSignature = () => {
        // Convert text to image using html-to-image library
        const signatureContainer = document.getElementById('signature-container');

        toPng(signatureContainer)
            .then((dataUrl) => {
                setSignatureUrl(dataUrl);
                if (setValue) {
                    setValue(dataUrl);
                }
            })
            .catch((error) => {
                console.error('Error generating signature:', error);
            });
    };

    useEffect(() => {
        if (name && name !== '') {
            if (useInitials) {
                const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

                let initials = [...name.matchAll(rgx)] || [];

                initials = (
                    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
                ).toUpperCase();
                setText(initials)
            } else {
                setText(name);
            }
        }
    }, [name])

    return (
        <div style={{paddingTop: '8px'}}>
            {signatureUrl ? (
                <img src={signatureUrl} alt="Generated Signature" />
            ) : (
                <Stack direction="row" alignContent="center" alignItems="center">
                    <div id="signature-container" className="signature-container">
                        {text}
                    </div>
                    <Button sx={{ ml: 2}} size='small' variant="contained" onClick={generateSignature}>Confirm {useInitials ? "Initials" : "Signature"}</Button>
                </Stack>
            )}
        </div>
    );
};
