import PropTypes from 'prop-types';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
// utils
import cssStyles from '../../utils/cssStyles';
//
import Iconify from '../Iconify';
import { IconButtonAnimate } from '../animate';

ToggleButton.propTypes = {
  notDefault: PropTypes.bool,
  onToggle: PropTypes.func,
  open: PropTypes.bool,
};

export default function ToggleButton({ notDefault, open, onToggle }) {
  return (
    <>
      <IconButtonAnimate
        color="default"
        onClick={onToggle}
        sx={{
          color: (open ? 'primary' : 'default'),
          transition: (theme) => theme.transitions.create('all')
        }}
      >
        <Iconify icon="fluent:dark-theme-20-filled" width={20} height={20} />
      </IconButtonAnimate>
    </>
  );
}
